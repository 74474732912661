import "bootstrap"
import "animate.css/animate.css"
import "bootstrap/dist/css/bootstrap.css"
import "@/assets/css/pages/pages.css"
import "@/assets/css/pages/pages-theme.css"

import {createApp} from "vue"
import {createPinia} from "pinia"
import App from "./App.vue"
import router from "./router"

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.mount('#app')

<script setup lang="ts">
import {onMounted} from "vue";

onMounted(() => {

})
</script>

<template>
  <section class="top-position1 pt-0 pb-5">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Our Blog</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'blog'}">Our Blog</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-0">
    <div class="container">
      <div class="row mt-3">
        <!-- blog left -->
        <div class="col-lg-8 mt-n2-9 mb-2-9 mb-lg-0">
          <div class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="200ms"
               style="visibility: visible; animation-delay: 200ms; animation-name: fadeIn;">
            <div class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 data-background="img/blog/blog-01.jpg"
                 style="background-image: url('/assets/img/display/hero-foundation.jpg');">
            </div>
            <div class="col-md-7">
              <div class="card card-style7 h-100">
                <div class="card-body">
                  <div class="p-2 bg-primary d-inline-block mb-3">
                    <h6 class="text-white mb-0 display-31">August 01, 2024</h6>
                  </div>
                  <h5 class="mb-0"><a href="#!">We ensures to you best the quality services.</a></h5>
                  <ul class="blog-meta-list">
                    <li><span class="ti-user pe-2 text-primary align-middle"></span><a href="#!">Darrell Coleman</a>
                    </li>
                    <li><span class="ti-tag pe-2 text-primary align-middle"></span>Household</li>
                  </ul>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry’s standard dummy text ever since.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="400ms"
               style="visibility: visible; animation-delay: 400ms; animation-name: fadeIn;">
            <div class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 data-background="img/blog/blog-02.jpg"
                 style="background-image: url('/assets/img/display/hero-foundation.jpg');">
            </div>
            <div class="col-md-7">
              <div class="card card-style7 h-100">
                <div class="card-body">
                  <div class="p-2 bg-primary d-inline-block mb-3">
                    <h6 class="text-white mb-0 display-31">March 10, 2024</h6>
                  </div>
                  <h5 class="mb-0"><a href="#!">Three reasons visibility matters in supply chain.</a></h5>
                  <ul class="blog-meta-list">
                    <li><span class="ti-user pe-2 text-primary align-middle"></span><a href="#!">Darrell Coleman</a>
                    </li>
                    <li><span class="ti-tag pe-2 text-primary align-middle"></span>Residential</li>
                  </ul>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry’s standard dummy text ever since.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="600ms"
               style="visibility: visible; animation-delay: 600ms; animation-name: fadeIn;">
            <div class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 data-background="img/blog/blog-03.jpg"
                 style="background-image: url('/assets/img/display/hero-foundation.jpg');">
            </div>
            <div class="col-md-7">
              <div class="card card-style7 h-100">
                <div class="card-body">
                  <div class="p-2 bg-primary d-inline-block mb-3">
                    <h6 class="text-white mb-0 display-31">August 21, 2024</h6>
                  </div>
                  <h5 class="mb-0"><a href="#!">These are the voyages of the starship enterprise.</a></h5>
                  <ul class="blog-meta-list">
                    <li><span class="ti-user pe-2 text-primary align-middle"></span><a href="#!">Darrell Coleman</a>
                    </li>
                    <li><span class="ti-tag pe-2 text-primary align-middle"></span>Residential</li>
                  </ul>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry’s standard dummy text ever since.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="800ms"
               style="visibility: visible; animation-delay: 800ms; animation-name: fadeIn;">
            <div class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 data-background="img/blog/blog-04.jpg"
                 style="background-image: url('/assets/img/display/hero-foundation.jpg');">
            </div>
            <div class="col-md-7">
              <div class="card card-style7 h-100">
                <div class="card-body">
                  <div class="p-2 bg-primary d-inline-block mb-3">
                    <h6 class="text-white mb-0 display-31">August 08, 2024</h6>
                  </div>
                  <h5 class="mb-0"><a href="#!">Its mission explore strange to the new worlds.</a></h5>
                  <ul class="blog-meta-list">
                    <li><span class="ti-user pe-2 text-primary align-middle"></span><a href="#!">Darrell Coleman</a>
                    </li>
                    <li><span class="ti-tag pe-2 text-primary align-middle"></span>Corporate</li>
                  </ul>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry’s standard dummy text ever since.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="1000ms"
               style="visibility: visible; animation-delay: 1000ms; animation-name: fadeIn;">
            <div class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 data-background="img/blog/blog-05.jpg"
                 style="background-image: url('/assets/img/display/hero-foundation.jpg');">
            </div>
            <div class="col-md-7">
              <div class="card card-style7 h-100">
                <div class="card-body">
                  <div class="p-2 bg-primary d-inline-block mb-3">
                    <h6 class="text-white mb-0 display-31">August 25, 2024</h6>
                  </div>
                  <h5 class="mb-0"><a href="#!">Parking shortage worsens in every city nowaday.</a></h5>
                  <ul class="blog-meta-list">
                    <li><span class="ti-user pe-2 text-primary align-middle"></span><a href="#!">Darrell Coleman</a>
                    </li>
                    <li><span class="ti-tag pe-2 text-primary align-middle"></span>Moving</li>
                  </ul>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry’s standard dummy text ever since.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="1200ms"
               style="visibility: visible; animation-delay: 1200ms; animation-name: fadeIn;">
            <div class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 data-background="img/blog/blog-06.jpg"
                 style="background-image: url('/assets/img/display/hero-foundation.jpg');">
            </div>
            <div class="col-md-7">
              <div class="card card-style7 h-100">
                <div class="card-body">
                  <div class="p-2 bg-primary d-inline-block mb-3">
                    <h6 class="text-white mb-0 display-31">August 05, 2024</h6>
                  </div>
                  <h5 class="mb-0"><a href="#!">Load boards show flat demand and higher rates.</a></h5>
                  <ul class="blog-meta-list">
                    <li><span class="ti-user pe-2 text-primary align-middle"></span><a href="#!">Darrell Coleman</a>
                    </li>
                    <li><span class="ti-tag pe-2 text-primary align-middle"></span>Corporate</li>
                  </ul>
                  <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry’s standard dummy text ever since.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row wow fadeIn" data-wow-delay="1400ms"
               style="visibility: hidden; animation-delay: 1400ms; animation-name: none;">
            <div class="col-sm-12">
              <!-- start pager  -->
              <div class="text-center mt-2-6 mt-lg-6">
                <div class="pagination">
                  <ul>
                    <li><a href="#!"><i class="fas fa-long-arrow-alt-left"></i></a></li>
                    <li class="active"><a href="#!">1</a></li>
                    <li><a href="#!">2</a></li>
                    <li><a href="#!">3</a></li>
                    <li><a href="#!">4</a></li>
                    <li><a href="#!"><i class="fas fa-long-arrow-alt-right"></i></a></li>
                  </ul>
                </div>
              </div>
              <!-- end pager -->
            </div>
          </div>
        </div>
        <!-- end blog left -->

        <!-- blog right -->
        <div class="col-lg-4">
          <div class="blog-sidebar ps-lg-1-6 ps-xl-1-9">
            <div class="widget search wow fadeIn" data-wow-delay="200ms"
                 style="visibility: visible; animation-delay: 200ms; animation-name: fadeIn;">
              <h6 class="widget-title">Search</h6>
              <form action="#!" class="search-bar">
                <input type="search" name="search" placeholder="Type here ...">
                <a href="#!" class="btn-newsletter"><i class="fa fa-search"></i></a>
              </form>
            </div>
            <div class="widget wow fadeIn" data-wow-delay="800ms"
                 style="visibility: visible; animation-delay: 800ms; animation-name: fadeIn;">
              <h6 class="widget-title">Latest Posts</h6>
              <div class="blog-post-carousel owl-carousel owl-theme owl-loaded owl-drag">

                <div class="owl-stage-outer">
                  <div class="owl-stage"
                       style="transform: translate3d(-681px, 0px, 0px); transition: 0.9s; width: 2725px;">
                    <div class="owl-item cloned" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Transport</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">August 19, 2024</a>
                          <h6><a href="#!">Parking shortage worsens in every city nowaday.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Relocation</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">August 25, 2024</a>
                          <h6><a href="#!">Load boards show flat demand and higher rates.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item active" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Household</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">August 01, 2024</a>
                          <h6><a href="#!">We ensures to you best the quality services.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Residential</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">March 15, 2024</a>
                          <h6><a href="#!">Three reasons visibility matters in supply chain.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Transport</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">August 19, 2024</a>
                          <h6><a href="#!">Parking shortage worsens in every city nowaday.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Relocation</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">August 25, 2024</a>
                          <h6><a href="#!">Load boards show flat demand and higher rates.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Household</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">August 01, 2024</a>
                          <h6><a href="#!">We ensures to you best the quality services.</a></h6>
                        </div>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 325.609px; margin-right: 15px;">
                      <div>
                        <div class="image-box">
                          <img src="/assets/img/display/hero-foundation.jpg" alt="...">
                          <h6>Residential</h6>
                        </div>
                        <div class="post-content">
                          <a href="#!" class="display-30 mb-2 d-block text-muted">March 15, 2024</a>
                          <h6><a href="#!">Three reasons visibility matters in supply chain.</a></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="owl-nav disabled">
                  <button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span>
                  </button>
                  <button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
                </div>
                <div class="owl-dots disabled"></div>
                <div class="owl-thumbs"></div>
              </div>
            </div>
            <div class="widget wow fadeIn" data-wow-delay="1200ms"
                 style="visibility: visible; animation-delay: 1200ms; animation-name: fadeIn;">
              <h6 class="widget-title">Popular Tags</h6>
              <ul class="blog-tags">
                <li><a href="#!">Moving</a></li>
                <li><a href="#!">Household</a></li>
                <li><a href="#!">International</a></li>
                <li><a href="#!">Relocation</a></li>
                <li><a href="#!">Transport</a></li>
                <li><a href="#!">Residential</a></li>
              </ul>
            </div>
            <div class="widget wow fadeIn" data-wow-delay="600ms"
                 style="visibility: visible; animation-delay: 600ms; animation-name: fadeIn;">
              <h6 class="widget-title">Categories</h6>
              <ul class="list-style9">
                <li><a href="#!">Household Moving</a><span>10</span></li>
                <li><a href="#!">Relocation Moving</a><span>15</span></li>
                <li><a href="#!">Residential Moving</a><span>04</span></li>
                <li><a href="#!">Moving Locally</a><span>06</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useApp} from "@/shared/app";
import ProjectsList from "@/apps/pages/comp/ProjectsList.vue";

const app = useApp()

const typedElement = ref<HTMLElement | null>(null)

onMounted(() => {
  app.helpers.document.initTyped(typedElement.value)
})
</script>

<template>
  <section class="bg-img cover-background full-screen top-position1 p-0 parallax" data-overlay-dark="8">
    <div class="container d-flex flex-column">
      <div class="row align-items-center min-vh-100 text-center caption">
        <div class="ms-auto me-auto col-md-10 col-lg-7 col-xl-8 mt-5 mt-lg-0">
          <span class="subtitle">
            Construction management services
          </span>
          <h1 class="text-white display-20 mb-2-3">
            Helping <span class="fw-bolder">Home</span> and <span class="fw-bolder">Business Owners</span> manage their
            building projects stay in time and within budget
          </h1>
          <router-link :to="{name:'book'}" class="butn white-hover my-2 me-4">
            <span class="label">Book Us Now</span>
          </router-link>

          <router-link :to="{name:'contact'}" class="butn white me-1">
            <span class="label">Contact Us</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <section class="about-style-11">
    <div class="container">
      <div class="row align-items-xl-center">
        <div class="col-lg-5 mb-1-6 mb-sm-1-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms"
             style="visibility: visible; animation-delay: 200ms; animation-name: fadeIn;">
          <div class="about-image-01">
            <img src="/assets/img/display/hero-foundation.jpg" alt="...">
            <div class="about-counter">
              <h4 class="h1 text-white mb-0 font-weight-700 countup">15</h4>
              <span class="text-white display-28 font-weight-600">Years</span>
            </div>
          </div>
          <div class="about-image-02">
            <img src="/assets/img/display/hero-flooring.jpg" alt="...">
          </div>
        </div>
        <div class="col-lg-7 wow fadeIn" data-wow-delay="400ms"
             style="visibility: visible; animation-delay: 400ms; animation-name: fadeIn;">
          <div class="ps-lg-1-6 ps-xl-10">
            <h2 class="mb-1-6">We manage construction projects big and small</h2>
            <div
                class="bg-very-light-gray mb-1-9 mb-sm-2-2 borders-start border-width-3 p-3 border-primary w-lg-90 w-xl-85">
              <p class="mb-0">
                We ensure that whether you are building a deck or new Coffee shop we make sure your project is delivered
                on time, within budget and at expected quality.
              </p>
            </div>
            <div class="row w-lg-90 w-lg-85">
              <div class="col-sm-6 mb-3">
                <div class="about-box-wrapper">
                  Epoxy
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="about-box-wrapper">
                  Electrical
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="about-box-wrapper">
                  Plumbing
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="about-box-wrapper">
                  Epoxy Projects
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="about-box-wrapper">
                  Foundation and Flooring Repair
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div class="about-box-wrapper">
                  Hardwood Flooring
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-0">
    <div class="container">
      <div class="section-heading4">
        <span>Recent Projects</span>
        <h2 class="mb-2 w-75">Our recent and completed projects</h2>
        <div class="w-40 ms-auto me-auto">
          Here are some of our recent projects. We always provide a look at the difficulties for each project.
        </div>
      </div>
      <projects-list/>
      <div class="text-center py-3">
        <router-link :to="{name:'projects'}" class="butn primary-hover rounded-pill my-2 me-4">
          <span class="label">
            View All Projects
          </span>
        </router-link>
      </div>
    </div>
  </section>
  <iframe
      class="w-100 border-top"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.7731103503966!2d-84.49780152462047!3d33.55927087334674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4e443ce4d3db9%3A0x530a80210a5fbdd2!2s135%20Doe%20Run%20Dr%2C%20Fairburn%2C%20GA%2030213%2C%20USA!5e0!3m2!1sen!2szw!4v1728811188966!5m2!1sen!2szw"
      height="450" style="border:0;" :allowfullscreen="true" loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"></iframe>
</template>
<style scoped>
.caption .subtitle {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 40px;
}

.caption .subtitle:before {
  top: 16px;
  left: -40px;
}

.caption .subtitle:after {
  top: 16px;
  left: auto;
  right: -40px;
}

.caption .subtitle:before, .caption .subtitle:after {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background: #ffffff;
}
</style>
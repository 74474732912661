<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useApp} from "@/shared/app";
import {EMessageType, type IMessage} from "@/shared/types";

const app = useApp()
const route = useRoute()

const message = ref<IMessage | null>(null)
const isLoading = ref<boolean>(true)
const routeName = ref<string>('')
const prevRoutName = ref<string>('')
const windowScrollY = ref<number>(0)
const navbarNavMenu = ref<HTMLElement | null>(null)
const navbarToggler = ref<HTMLElement | null>(null)

watch(route, () => {
  let scrollTop = true
  routeName.value = route.name ? route.name as string : ''
  if (routeName.value == 'projects:details') {
    scrollTop = false
  }
  if (routeName.value == 'projects' && prevRoutName.value == 'projects:details') {
    scrollTop = false
  }
  if (scrollTop) {
    app.helpers.window.scrollTop()
  }
  app.helpers.document.setTitle(app.config.pagesTitlePrefix, route.meta.title)
  prevRoutName.value = route.name ? route.name as string : ''
})

watch(navbarToggler, () => {
  if (navbarToggler.value) {
    navbarToggler.value.addEventListener('click', () => {
      if (navbarToggler.value) {
        navbarToggler.value.classList.toggle('menu-opened')
        if (navbarNavMenu.value) {
          if (!navbarNavMenu.value.classList.contains('open')) {
            navbarNavMenu.value.classList.add('open')
            navbarNavMenu.value.style.display = 'block'
          } else {
            navbarNavMenu.value.classList.remove('open')
            navbarNavMenu.value.style.display = ''
          }
        }
      }
    })
  }
})

onMounted(async () => {
  isLoading.value = true
  windowScrollY.value = window.scrollY
  try {
    const success = await app.setup()
    if (!success) {
      message.value = {
        type: EMessageType.Danger,
        body: app.messages.unexpectedError,
      }
    }
  } catch (ex) {
    message.value = {
      type: EMessageType.Danger,
      body: app.messages.fromException(ex),
    }
    app.logger.error(ex)
  }
  await app.helpers.async.sleep(2000)
  isLoading.value = false
})

window.addEventListener('scroll', () => {
  windowScrollY.value = window.scrollY
})
</script>

<template>
  <template v-if="isLoading">
    <div id="preloader"></div>
  </template>
  <div class="main-wrapper">
    <header class="header header-style1 menu-area-light" :class="{'scroll-header':windowScrollY>50}">
      <div class="navbar-default border-bottom border-color-light-white">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-lg-12">
              <div class="menu_area">
                <nav class="navbar navbar-expand-lg navbar-light p-0 current">

                  <div class="navbar-header navbar-header-custom">
                    <router-link :to="{name:'home'}" class="navbar-brand">
                      <img src="/assets/img/app/app-logo.png"
                           :class="windowScrollY > 50 ? 'd-inline':'d-none'"
                           alt="BoldAbode">
                      <img src="/assets/img/app/app-logo-white.png"
                           :class="windowScrollY <= 50 ? 'd-inline':'d-none'"
                           alt="BoldAbode">
                    </router-link>
                  </div>

                  <div ref="navbarToggler" class="navbar-toggler"></div>
                  <ul ref="navbarNavMenu" class="navbar-nav ms-auto">
                    <li :class="{'current':routeName.startsWith('home')}">
                      <router-link :to="{name:'home'}">
                        Home
                      </router-link>
                    </li>
                    <li :class="{'current':routeName.startsWith('projects')}">
                      <router-link :to="{name:'projects'}">
                        Projects
                      </router-link>
                    </li>
                    <li :class="{'current':routeName.startsWith('blog')}">
                      <router-link :to="{name:'blog'}">
                        Our Blog
                      </router-link>
                    </li>
                    <li :class="{'current':routeName.startsWith('contact')}">
                      <router-link :to="{name:'contact'}">
                        Contact Us
                      </router-link>
                    </li>
                  </ul>
                  <div class="attr-nav align-items-lg-center ms-lg-auto">
                    <ul>
                      <li class="d-none d-lg-inline-block">
                        <router-link :to="{name:'book'}" class="butn small" :class="{'white-hover':windowScrollY<=50}">
                          Book Us Now
                        </router-link>
                      </li>
                    </ul>
                  </div>

                </nav>
              </div>
            </div>
          </div>
        </div>

      </div>
    </header>
    <router-view/>
    <footer>
      <div class="container">
        <div class="row mt-n2-6">
          <div class="col-sm-6 col-lg-3 mt-2-6 wow fadeIn" data-wow-delay="200ms">
            <div class="mb-1-6 mb-lg-1-9">
              <img src="/assets/img/app/app-logo-white.png" alt="...">
            </div>
            <p class="text-white mb-1-6 mb-lg-1-9">
              We ensure that whether you are building a deck or new Coffee shop we make sure your project is delivered
              on time, within budget and at expected quality.
            </p>
          </div>
          <div class="col-sm-6 col-lg-3 mt-2-6 wow fadeIn" data-wow-delay="400ms">
            <div class="ps-sm-1-6 ps-xl-2-5">
              <h3 class="h5 mb-1-6 mb-lg-1-9 text-white">Get in touch</h3>
              <ul class="address-list">
                <li>
                  <span class="d-inline-block align-middle"><i
                      class="far fa-envelope text-primary align-middle"></i></span>
                  <span class="d-inline-block align-middle ps-2 text-white">info@boldabode.com</span>
                </li>
                <li>
                  <span class="d-inline-block align-middle"><i
                      class="fas fa-phone text-primary align-middle"></i></span>
                  <span class="d-inline-block align-middle ps-2 text-white">727-210-9926</span>
                </li>
                <li>
                  <span class="d-inline-block align-middle"><i
                      class="fas fa-map-marker-alt text-primary align-middle"></i></span>
                  <span class="d-inline-block align-middle ps-2 text-white">
                    135 Doe Run Fairburn, Atlanta, GA 30213
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-lg-2 mt-2-6 wow fadeIn" data-wow-delay="600ms">
            <div class="ps-lg-1-6 ps-xl-2-5">
              <h3 class="h5 mb-1-6 mb-lg-1-9 text-white">Quick links</h3>
              <ul class="footer-list-style2">
                <li>
                  <router-link :to="{name:'projects'}">Projects</router-link>
                </li>
                <li>
                  <router-link :to="{name:'book'}">Book Us</router-link>
                </li>
                <li>
                  <router-link :to="{name:'contact'}">Contact Us</router-link>
                </li>
                <li>
                  <router-link :to="{name:'blog'}">Our Blog</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 mt-2-6 wow fadeIn" data-wow-delay="800ms">
            <div class="ps-sm-1-6 ps-xl-2-5">
              <h3 class="h5 mb-1-6 mb-lg-1-9 text-white">Recent posts</h3>
              <div class="media mb-1-6 align-middle">
                <img src="/assets/img/display/hero-foundation.jpg" style="width: 80px" alt="...">
                <div class="media-body align-self-center ms-3">
                  <h4 class="display-30"><a href="#" class="text-white font-weight-500">We craft marketing &amp;
                    digital products that.</a></h4>
                  <p class="text-white mb-0 display-31">Oct 13, 2022</p>
                </div>
              </div>
              <div class="media mb-1-6 align-middle">
                <img src="/assets/img/display/hero-flooring.jpg" style="width: 80px" alt="...">
                <div class="media-body align-self-center ms-3">
                  <h4 class="display-30"><a href="#" class="text-white font-weight-500">Step by step guide to clean
                    your carpets.</a></h4>
                  <p class="text-white mb-0 display-31">Oct 12, 2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bar borders-top border-color-light-white wow fadeIn" data-wow-delay="200ms"
           style="visibility: visible; animation-delay: 200ms; animation-name: fadeIn;">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center text-lg-start mt-3 mt-md-0 order-2 order-md-1">
              <p class="d-inline-block text-white mb-0">
                © <span class="current-year">{{ (new Date()).getFullYear() }}</span>
                <router-link :to="{name:'home'}" class="text-primary white-hover">
                  BoldAbode
                </router-link>
              </p>
            </div>
            <div class="col-md-6 text-center text-lg-end order-1 order-md-2">
              <p class="text-white display-30 mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<style>
.cover-background {
  background-image: url("/assets/img/display/hero-foundation.jpg");
}
</style>
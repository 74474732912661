<script setup lang="ts">
import {onMounted} from "vue";

onMounted(() => {

})
</script>

<template>
  <section class="top-position1 pt-0">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Legal</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'legal'}">Legal</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>